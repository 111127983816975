<script>
import Layout from "@/router/layouts/main";
import { mapState } from "vuex";
import { api } from "@/state/services";
import Banners from "@/components/widgets/banners";
import Stat from "@/components/widgets/stat";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
    Banners,
    Stat,
  },
  computed: {
    ...mapState({
      account: state => state.account
    }),
  },
  data() {
    return {
      statData: [
        { type: "line", title: "Pedidos", value: "-", icon: "bx-shopping-bag", },
        { type: "line", title: "Vendas Realizadas", value: "R$ -,--", icon: "bx-dollar-circle", },
        { type: "line", title: "Ticket Médio", value: "R$ -,--", icon: "bx-cart", },
        { type: "line", title: "Taxa de Conversão", value: "-%", icon: "bx-line-chart", },
        { type: "line", title: "Pagamento Pendente", value: "-", icon: "bxs-dollar-circle", },
        { type: "line", title: "Nota Fiscal Pendente", value: "-", icon: "bx-receipt", },
        { type: "line", title: "Envio Pendente", value: "-", icon: "bx-cube", },
        { type: "line", title: "Entrega em Andamento", value: "-", icon: "bxs-truck", },
      ]
    };
  },
  methods: {
    getTotals() {
      api
        .get("reports/totals")
        .then((response) => {
          if (response.data.status == "success") {
            this.statData = [
              { type: "line", title: "Pedidos", value: response.data.total.orders.toString(), icon: "bx-shopping-bag", },
              { type: "line", title: "Vendas Realizadas", value: this.$options.filters.currency(response.data.total.sales).toString(), icon: "bx-dollar-circle", },
              { type: "line", title: "Ticket Médio", value: this.$options.filters.currency(response.data.total.average).toString(), icon: "bx-cart", },
              { type: "line", title: "Taxa de Conversão", value: response.data.total.conversion.toString(), icon: "bx-line-chart", },
              { type: "line", title: "Pagamento Pendente", value: response.data.total.payment_pending.toString(), icon: "bxs-dollar-circle", },
              { type: "line", title: "Nota Fiscal Pendente", value: response.data.total.nfe_pending.toString(), icon: "bx-receipt", },
              { type: "line", title: "Envio Pendente", value: response.data.total.shipping_pending.toString(), icon: "bx-cube", },
              { type: "line", title: "Entrega em Andamento", value: response.data.total.shipped.toString(), icon: "bxs-truck", },
            ];
          }
        })
      },
  },
  mounted() {
    this.getTotals();
  }
};
</script>

<template>
  <Layout>
    <router-link v-if="account.user && account.user.sales && account.user.sales == 'inactive'" class="bg-soft-danger text-dark rounded p-3 mb-4 d-flex align-items-center" tag="a" to="/settings">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(49, 47, 79, 1);">
          <path
            d="M12 22c4.879 0 9-4.121 9-9s-4.121-9-9-9-9 4.121-9 9 4.121 9 9 9zm0-16c3.794 0 7 3.206 7 7s-3.206 7-7 7-7-3.206-7-7 3.206-7 7-7zm5.284-2.293 1.412-1.416 3.01 3-1.413 1.417zM5.282 2.294 6.7 3.706l-2.99 3-1.417-1.413z">
          </path>
          <path d="M11 9h2v5h-2zm0 6h2v2h-2z"></path>
        </svg>
      </div>
      <div class="ml-2">
        <strong>Atenção:</strong> As vendas da sua loja estão desativadas no momento.
      </div>
    </router-link>

    <Banners></Banners>

    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <h6 class="m-0">Seja bem-vindo à Área do Parceiro</h6>
        <h6 v-if="account.user && account.user.name" class="m-0 text-right">{{ account.user.name }}</h6>
      </div>
    </div>

    <div class="row">
      <div v-for="stat of statData" :key="stat.icon" class="col-md-6 col-lg-3 col-xl-3">
        <Stat :type="stat.type" :icon="stat.icon" :title="stat.title" :value="stat.value" />
      </div>
    </div>
  </Layout>
</template>